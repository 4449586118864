@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates&family=Montserrat:wght@300;400;500&display=swap");
#ah-html,
#ah-body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}
#ah-chat-root {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
