@-webkit-keyframes TypingIndicatorDotAnimation {
  0% {
    transform: translateY(0);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0);
  }
}

@keyframes TypingIndicatorDotAnimation {
  0% {
    transform: translateY(0);
  }
  28% {
    transform: translateY(-5px);
  }
  44% {
    transform: translateY(0);
  }
}
